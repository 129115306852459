/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav0 from './Nav0';
import Banner4 from './Banner4';
import Feature7 from './Feature7';
import Pricing0 from './Pricing0';
import Pricing1 from './Pricing1';
import Feature4 from './Feature4';
import Footer1 from './Footer1';
import Content1 from './Content1';

import {
  Nav00DataSource,
  Banner40DataSource,
  Feature70DataSource,
  Pricing00DataSource,
  Pricing10DataSource,
  Pricing11DataSource,
  Feature40DataSource,
  Footer10DataSource,
  Content10DataSource,
} from './data.source';
import './less/antMotionStyle.less';
import { Col, Row, Icon, Radio, Card } from 'antd';
import { FacebookOutlined, InstagramOutlined, TwitterOutlined, WhatsAppOutlined, YoutubeOutlined } from '@ant-design/icons'

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

const options = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Annually', value: 'annually' },
];

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      pricePeriod: 'monthly'
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <div>
        <Row style={{ backgroundColor: "#900000", color: "#ffffff", paddingLeft: 50, paddingRight: 50, paddingTop: 5, paddingBottom: 5 }}>
          <Col span={12}>Realesta Property Management System</Col>
          <Col span={12} style={{ textAlign: 'right' }}>+256 784 000 123 | info@realestapms.com
          <FacebookOutlined style={{ marginLeft: 30, marginRight: 10 }} />
            <TwitterOutlined style={{ marginLeft: 10, marginRight: 10 }} />
            <InstagramOutlined style={{ marginLeft: 10, marginRight: 10 }} />
            <WhatsAppOutlined style={{ marginLeft: 10, marginRight: 10 }} />
            <YoutubeOutlined style={{ marginLeft: 10, marginRight: 10 }} />
          </Col>
        </Row>
      </div>,

      <Nav0
        id="Nav0_0"
        key="Nav0_0"
        dataSource={Nav00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner4
        id="Banner4_0"
        key="Banner4_0"
        dataSource={Banner40DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature7
        id="Feature7_0"
        key="Feature7_0"
        dataSource={Feature70DataSource}
        isMobile={this.state.isMobile}
      />,
      <div style={{ textAlign: 'center', padding: 30, backgroundColor: '#f7f9fc' }}>
        <div key="title" {...Pricing10DataSource.titleWrapper} >
          <h1 className={'pricing1-title-h1'} style={{ color: '#0050ff', paddingBottom: 20, fontFamily: 32 }}>
            <span>
              <p>Pricing</p>
            </span>
          </h1>
        </div>
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          options={options}
          value={this.state.pricePeriod}
          onChange={e => this.setState({ pricePeriod: e.target.value })} value={this.state.pricePeriod}
        />
      </div>,
      this.state.pricePeriod === "monthly" ?
        <Pricing1
          id="Pricing1_0"
          key="Pricing1_0"
          dataSource={Pricing10DataSource}
          isMobile={this.state.isMobile}
        />
        :
        <Pricing1
          id="Pricing1_0"
          key="Pricing1_0"
          dataSource={Pricing11DataSource}
          isMobile={this.state.isMobile}
        />
      ,

      <Pricing0
        id="Pricing0_0"
        key="Pricing0_0"
        dataSource={Pricing00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature4
        id="Feature4_0"
        key="Feature4_0"
        dataSource={Feature40DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content1
        id="Content1_0"
        key="Content1_0"
        dataSource={Content10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
