import React from 'react';
import logo from './assets/logo.png'
import logoWhite from './assets/logo_white.png'
import diagram from './assets/diagram.png'
import check from './assets/check.png'
import shot from './assets/shot.png'
import laptop from './assets/laptop.png'
import contact from './assets/contact.png'
import { Divider } from 'antd';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper kh4si7loilj-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: logo,
  },
  Menu: {
    className: 'header0-menu kh4sisf7zv9-editor_css',
    children: [
      {
        name: 'item1',
        className: 'header0-item kh4sp4op8fg-editor_css',
        children: {
          href: '#Feature7_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>Features</p>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'kh4spn9hd9g-editor_css',
            },
          ],
          target: '',
        },
      },
      {
        name: 'item~kh4u2c7ljmj',
        className: 'header0-item kh4sp4op8fg-editor_css',
        children: {
          href: '#Feature4_0',
          children: [
            {
              children: (
                <span>
                  <p>Why Realesta PMS?</p>
                </span>
              ),
              name: 'text',
              className: 'kh4spn9hd9g-editor_css',
            },
          ],
          target: '',
        },
      },
      {
        name: 'item~5',
        className: 'header0-item kh4sp4op8fg-editor_css',
        children: {
          href: '#Pricing1_0',
          children: [
            {
              children: (
                <span>
                  <p>Pricing</p>
                </span>
              ),
              name: 'text',
              className: 'kh4spn9hd9g-editor_css',
            },
          ],
          target: '',
        },
      },
      {
        name: 'item~kh4u2fmku2r',
        className: 'header0-item kh4sp4op8fg-editor_css',
        children: {
          href: '#Content1_0',
          children: [
            {
              children: (
                <span>
                  <p>Contact Us</p>
                </span>
              ),
              name: 'text',
              className: 'kh4spn9hd9g-editor_css',
            },
          ],
          target: '',
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner40DataSource = {
  wrapper: { className: 'home-page-wrapper banner4 kh4s9iy6zg-editor_css' },
  page: { className: 'home-page banner4-page' },
  childWrapper: {
    className: 'banner4-title-wrapper kh4mur82uji-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Realesta PMS</p>
          </span>
        ),
        className: 'banner4-title kh4s9pt5gn-editor_css',
      },
      {
        name: 'content~kh4s7vngkp',
        className: 'kh4q3i6ukga-editor_css',
        children: (
          <span>
            <p>MANAGING PROPERTIES...</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner4-content',
        children: (
          <span>
            <p>
              Designed and built from ground by real property managers focused on solving daily challenges faced in the property management industry</p>
          </span>
        ),
      },
      {
        name: 'button',
        children: {
          href: '#Pricing1_0',
          type: 'primary',
          children: (
            <span>
              <p>Get started today</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner4-image kh4mspcpte-editor_css',
    children:
      shot,
  },
};
export const Feature70DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature7-wrapper kh4n4pv8s6-editor_css',
  },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>Features</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <span>
              <p>Some of the features that makes Realesta PMS great.</p>
            </span>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>Tenant Management</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>Store, update, and access tenants information</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Staff Management</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Store, update, and access staff information</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>Landlords Management</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>Store, update, and access landlords information</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>Invoicing and Receipting</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      Generate, store and print receipts and invoices.
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>Utilities Tracking</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      Bill &amp; track utilities i.e water power and garbage.
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Rent arrears Tracking</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Track tenants payment &amp; keep track of their arrears.
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>Complaint Management</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Store, update, and access tenants complaints and progress.<br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>Properties Management</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>Store, update, and access Properties information.</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~kh4of66bg2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Renovations &amp; Repairs</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      Store, update, and access tenants renovations &amp; repairs.{' '}
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~kh4of7hzbt9',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>Email &amp; SMS intergration</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Notify tenants and contractors on information i.e reminder notices
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~kh4of87ccm',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>Reporting</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      Generate activity reports for tenants , property managers &amp; landlords.
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~kh4of934eth',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>Expense management</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>Store, update, and access expense information.</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      laptop,
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>SIMPLIFY. AUTOMATE. GROW.</p>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              Whether you are a landlord managing own units or a property manager with multiple units, Realesta PMS includes all the features you need to make your operation the most efficient it has ever been.
            </p>
          </span>
        ),
        className: 'pricing0-content',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <span>
              <p>Free for 3 months</p>
            </span>
          </span>
        ),
        className: 'pricing0-pricing',
      },
      {
        name: 'button',
        children: {
          href: '#Content1_0',
          type: 'primary',
          children: (
            <span>
              <span>
                <span>
                  <p>Find out more</p>
                </span>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};

export const Pricing10DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: 'home-page pricing1 khwzho5vilu-editor_css' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Pricing</p>
          </span>
        ),
        className: 'pricing1-title-h1',
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <p>Starter</p>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money khw3q5bpwdn-editor_css',
            children: (
              <span>
                UGX 50,000<br />
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      0 - 50 Units<br />Free Cloud backups<br />Free
                      notifications<br />Free reports<br />Unlimited emails<br />
                    </span>
                  </span>
                </span>
                <div>
                  <span>
                    <span>
                      <span>
                        Free product support (Limited)<br />
                      </span>
                    </span>
                  </span>
                </div>
                <div>
                  <p>
                    <span>
                      <span>
                        <span>Free PM consultation (Limited)</span>
                      </span>
                    </span>
                  </p>
                </div>
                <div>
                  <span>
                    <span>
                      <span />
                    </span>
                  </span>
                </div>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: (
                  <span>
                    <p>
                      Get Started<br />
                    </p>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block~khx30h9l7vt',
        className: 'pricing1-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <p>Basic</p>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money khw3slgi36-editor_css',
            children: (
              <span>
                <p>UGX 70,000</p>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <p>51 - 150 Units</p>
                <p>Free Cloud backups</p>
                <p>Free notifications</p>
                <p>Free reports</p>
                <p>Unlimited emails</p>
                <p>Free product support (Limited)</p>
                <p>Free PM consultation (Limited)</p>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: (
                  <span>
                    <p>Get Started</p>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <p>
                  Pro<br />
                </p>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money khw3sc2ijqc-editor_css',
            children: (
              <span>
                <p>
                  UGX 100,000<br />
                </p>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <span>
                    151 - 300 Units<br />
                  </span>
                </span>
                <p>Free Cloud backups</p>
                <p>Free notifications</p>
                <p>Free reports</p>
                <p>Unlimited emails</p>
                <p>Free product support</p>
                <p>Free PM consultation</p>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: (
                  <span>
                    <span>
                      <p>Get Started</p>
                    </span>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block~khw3og4x0tr',
        className: 'pricing1-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <p>Enterprise</p>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money khw3slgi36-editor_css',
            children: (
              <span>
                <span>
                  <p>
                    Get a Quote<br />
                  </p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      Above 300<br />
                    </span>
                  </span>
                  <p>Free Cloud backups</p>
                  <p>Free notifications</p>
                  <p>Free reports</p>
                  <p>Unlimited emails</p>
                  <p>Free product support</p>
                  <p>Free PM consultation</p>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: (
                  <span>
                    <p>Get Started</p>
                  </span>
                ),
              },
            },
          },
        },
      },
    ],
  },
};

export const Pricing11DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: 'home-page pricing1 khwzho5vilu-editor_css' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Pricing</p>
          </span>
        ),
        className: 'pricing1-title-h1',
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <p>Starter</p>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money khw3q5bpwdn-editor_css',
            children: (
              <span>
                UGX 500,000<br />
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      0 - 50 Units<br />Free Cloud backups<br />Free
                      notifications<br />Free reports<br />Unlimited emails<br />
                    </span>
                  </span>
                </span>
                <div>
                  <span>
                    <span>
                      <span>
                        Free product support (Limited)<br />
                      </span>
                    </span>
                  </span>
                </div>
                <div>
                  <p>
                    <span>
                      <span>
                        <span>Free PM consultation (Limited)</span>
                      </span>
                    </span>
                  </p>
                </div>
                <div>
                  <span>
                    <span>
                      <span />
                    </span>
                  </span>
                </div>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: (
                  <span>
                    <p>
                      Get Started<br />
                    </p>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block~khx30h9l7vt',
        className: 'pricing1-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <p>Basic</p>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money khw3slgi36-editor_css',
            children: (
              <span>
                <p>UGX 700,000</p>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <p>51 - 150 Units</p>
                <p>Free Cloud backups</p>
                <p>Free notifications</p>
                <p>Free reports</p>
                <p>Unlimited emails</p>
                <p>Free product support (Limited)</p>
                <p>Free PM consultation (Limited)</p>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: (
                  <span>
                    <p>Get Started</p>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <p>
                  Pro<br />
                </p>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money khw3sc2ijqc-editor_css',
            children: (
              <span>
                <p>
                  UGX 1,000,000<br />
                </p>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <span>
                    151 - 300 Units<br />
                  </span>
                </span>
                <p>Free Cloud backups</p>
                <p>Free notifications</p>
                <p>Free reports</p>
                <p>Unlimited emails</p>
                <p>Free product support</p>
                <p>Free PM consultation</p>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: (
                  <span>
                    <span>
                      <p>Get Started</p>
                    </span>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block~khw3og4x0tr',
        className: 'pricing1-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <p>Enterprise</p>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money khw3slgi36-editor_css',
            children: (
              <span>
                <span>
                  <p>
                    Get a Quote<br />
                  </p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      Above 300<br />
                    </span>
                  </span>
                  <p>Free Cloud backups</p>
                  <p>Free notifications</p>
                  <p>Free reports</p>
                  <p>Unlimited emails</p>
                  <p>Free product support</p>
                  <p>Free PM consultation</p>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: (
                  <span>
                    <p>Get Started</p>
                  </span>
                ),
              },
            },
          },
        },
      },
    ],
  },
};

export const Feature40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kh4oxta25rk-editor_css',
  },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper kh4sec9mrfu-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Why Realesta PMS</p>
            </span>
          </span>
        ),
        className: 'title-h1 kh4sdn34537-editor_css',
      },
    ],
  },
  img: {
    children: diagram,
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            check,
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>Experience</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                For several years we have understood the needs of landlords and tenants for mutual harmonization and satisfaction
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            check,
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>Affordable Package</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                Our well thought out packages are designed with multiple product users in mind ranging from landlords with own units, to property management companies.
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            check,
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>Product Support</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                We train you and/or your staff about the software usage and offer continuous user support on demand
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block~kh4owkecx3',
        img: {
          className: 'content6-icon',
          children:
            check,
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>Free Consultancy</p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>We offer free property management consultancy</p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~kh4owm6lokd',
        img: {
          className: 'content6-icon',
          children:
            check,
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>Data Security</p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      We offer encrypted data backup that you can access anytime
                    </p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block~kh4owr5hcba',
        img: {
          className: 'content6-icon',
          children:
            check,
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <p>Special Offer</p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  You get use the software for free for a period of up to 3
                  months
                </p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: <img width="100%" src={logoWhite} alt="img" />
          ,
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: 'Realesta PMS is a property management softwares designed for property managers and landlords to manage and track rental activity',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Resources' },
        childWrapper: {
          children: [
            { name: 'link0', href: 'https//:www.realestapms.com', children: 'Realesta' },
            { name: 'link1', href: 'https//:www.madrachi.ug', children: 'Madrachi Co LTD' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>Location</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>Maria tusabire building, Kireka, Kamuli road</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <span>
                    <p>info@realestapms.com</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>Contact Info</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>+256 756 778 123</p>
                  <p>+256 782 171 843</p>
                  <hr />
                  <p>+256 784 000 123</p>
                  <p>+256 752 990 869</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <p>info@realestapms.com</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2020<a href="https://madrachi.ug" target="_blank">
          {' '}
          Madrachi Company Limited
        </a>{' '}
        | All rights reserved
      </span>
    ),
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: contact,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Contact Us' },
  content: {
    className: 'content1-content',
    children:
      'Drop us a line with questions, inquiries, or requests - we\'re happy to assist.',
  },
};
