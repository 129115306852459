import React, { useState } from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col, Form, Input, InputNumber, Button, Descriptions, Spin, message } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import Axios from 'axios';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};



const Content1 = (props) => {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;

  const [loadingState, setLoadingState] = useState(false)
  const [form] = Form.useForm();


  const onFinish = ({ user }) => {

    setLoadingState(true)
    console.log(user);


    // Axios.get(`/send_email/${user.name}/${user.email}/${user.message}`, { ...user })
    Axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' },
      url: `https://realestapms.com/sendemailforrealestapms.php?message=${user.message}&name=${user.name}&email=${user.email}`,
      data: user
    }).then(function (response) {
      console.log(response.data);
    }).then(function (response) {
      form.resetFields();
      setLoadingState(false)
      message.success("Congrats, your message was sent successfully.")
    })
      .catch(e => console.log(e))

  };


  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
        scaleY: '+=0.3',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      }
      : {
        x: '-=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
  };
  return (
    <div style={{ backgroundColor: '#f7f9fc' }} {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <span {...dataSource.img}>
            <img src={dataSource.img.children} width="100%" alt="img" />
          </span>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
          style={{ marginTop: 0, paddingLeft: 60 }}
        >
          <h2 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <div key="p" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          <div key="form" style={{ marginTop: 30 }}>
            <Spin spinning={loadingState}>
              <Form form={form} {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                <Form.Item name={['user', 'name']} label="Name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name={['user', 'email']} label="Email" rules={[{ required: true, type: 'email' }]}>
                  <Input />
                </Form.Item>

                <Form.Item name={['user', 'message']} label="Message" rules={[{ required: true }]}>
                  <Input.TextArea />
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
        </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </QueueAnim>
        {/* <Descriptions column={3} style={{ padding: 32 }} title="Contact Information">
          <Descriptions.Item label="Telephone">+256 756 778 123 | +256 784 000 123</Descriptions.Item>
          <Descriptions.Item label="Telephone">+256 756 778 123 | +256 784 000 123</Descriptions.Item>
          <Descriptions.Item label="Email">info@realesta.com</Descriptions.Item>
          <Descriptions.Item label="Address">
            Maria Tusabire Building, Kireka-Kamuli Rd, Kampala
    </Descriptions.Item>
        </Descriptions> */}
      </OverPack>
    </div>
  );
}

export default Content1;
